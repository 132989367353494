<template>
<div>
    <v-card>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar dense class="tc-title">
            <v-toolbar-title>Feedback Report Details</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <br>
        <center>
            <table class="table table-bordered display" style="font-size: 10px">
                <tr>
                    <th>Feedback Type:</th>
                    <th>Academic Year:</th>
                    <th>Semester</th>
                </tr>
                <tr>
                    <td>{{feedback_type}}</td>
                    <td>{{ay}}</td>
                    <td>{{sem}}</td>
                </tr>
                <tr>
                    <th>Feedback Number:</th>
                    <th>Start Date:</th>
                    <th>End Date:</th>
                </tr>
                <tr>
                    <td>{{feedback_number}}</td>
                    <td>{{start_date}}</td>
                    <td>{{end_date}}</td>
                </tr>
                <tr>
                    <th>Max Mark:</th>
                    <th>Obtained Mark:</th>
                </tr>
                <tr>
                    <td>{{max_mark}}</td>
                    <td>{{obtained_mark}}</td>
                </tr>
            </table>
        </center>
        <br /><br />
        <div>
            <v-row justify="end" class="mr-5">
                <v-btn v-if="feedback_report_details_list.length != 0" @click="exportexcel()" color="success" dark class="mb-2">
                    <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>
            <v-data-table  id="exceltable"  :headers="headers" :items="feedback_report_details_list"  class="elevation-1" :search="search" >
                <template v-slot:top class="red">
                    <v-toolbar flat color="white">
                        <v-toolbar-title>Feedback Report Details List</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                        <span style="width: 20px"></span>
                    </v-toolbar>
                </template>
            </v-data-table>
        </div>
        
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-card>
    <br />
</div>
</template>

<script>
    import axios from "axios";
    import {
        table2excel
    } from "../jquery.table2excel";
    import readXlsxFile from "read-excel-file";
    export default {
        data: () => ({
            overlay: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",

            feedback_report_details_list: [],
            feedback_count_id_list: [],
            feedback_type_id: "",
            feedback_type: "",
            ay: "",
            sem: "",
            feedback_number: "",
            start_date: "",
            end_date: "",
            max_mark: "",
            obtained_mark: "",

            headers:[
                { 
                    text: 'Sr.No.', 
                    value: 'SrNo', 
                    align: 'left', 
                    sortable: true
                },
                { 
                    text: 'Feedback Type', 
                    value: 'feedback_type', 
                    align: 'left', 
                    sortable: true
                },
                { 
                    text: 'Ouestion Type', 
                    value: 'question_type', 
                    align: 'left', 
                    sortable: true
                },
                { 
                    text: 'Ouestion', 
                    value: 'question', 
                    align: 'left', 
                    sortable: true
                },
                { 
                    text: 'Max Marks', 
                    value: 'max_mark', 
                    align: 'left', 
                    sortable: true
                },
                { 
                    text: 'Obtained Marks', 
                    value: 'obtained_mark', 
                    align: 'left', 
                    sortable: true
                },
            ]
            

        }),

        mounted() {
            if(!this.$route.params.feedback_count_id || !this.$route.params.feedback_type_id){
                //alert("1 =>>"+ this.$route.params.feedback_count_id)
                //alert("2 =>>"+ this.$route.params.feedback_type_id)
                this.$router.push("admin-feedback-report");
            }
            this.feedback_count_id_list = this.$route.params.feedback_count_id;
            this.feedback_type_id = this.$route.params.feedback_type_id;
            const data = {
                feedback_count_id_list:this.feedback_count_id_list,
                feedback_type_id:this.feedback_type_id
            };
            axios
            .post("/Admin/fetchFeedbackReportDetails", data)
            .then(res => {
                if (res.data.msg == "200") {
                    this.feedback_report_details_list = res.data.feedback_report_details_list;
                    this.feedback_type = res.data.feedback_type;
                    this.ay = res.data.ay;
                    this.sem = res.data.sem;
                    this.feedback_number = res.data.feedback_number;
                    this.start_date = res.data.start_date;
                    this.end_date = res.data.end_date;
                    this.max_mark = res.data.max_mark;
                    this.obtained_mark = res.data.obtained_mark;
                } 
                else {
                    console.log("Error Fetching Data..!");
                }
            });
        },

        methods: {
            exportexcel() {
                $("#exceltable").table2excel({
                    name: "Worksheet Name",
                    filename: "Feedback_Report_Details",  //do not include extension
                    fileext: ".xls"                       // file extension
                });
            },//end of function

            fetchReport() {
                this.regdashboard_list = []
                this.overlay = true;
                const data = {
                    instituteid: this.institute,
                    programtypeid: this.programtype,
                    programid: this.program,
                    ay: this.ay,
                    placementtype: this.placementtype,
                };
                axios
                    .post("Admin/fetchFeedbackReportDetails", data)
                    .then((res) => {
                        this.overlay = false;
                        if (res.data.msg == "200") {
                            this.ayid = res.data.ayid;
                            this.withlearnerdetailsonfetch = this.withlearnerdetails;
                            this.placementtypeid = res.data.placementtypeid;
                            this.progid = res.data.progid;
                            this.ptid = res.data.ptid;
                            this.orgid = res.data.orgid;
                            this.yearfinaltotal = res.data.yearfinaltotal;
                            this.regdashboard_list = res.data.regdashboard_list;
                            this.yearmasterlist = res.data.yearmasterlist;
                            this.yeartotal_list = res.data.yeartotal_list;
                            if (this.regdashboard_list.length < 1) {
                                this.showSnackbar("red", "Data Not Found");
                            }
                        } else {
                            this.showSnackbar("red", res.data.error);
                        }
                    }).catch((error) => {
                        this.overlay = false;
                        window.console.log(error);
                    }).finally(() => {
                        this.overlay = false;
                    });
            },//end of function

            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },//end of function

            clear() {
                this.excelfile = null;
                this.exceldialog = false;
            },//end of function

            closeerrordialog() {
                this.clear();
                this.excelerrordialog = false;
            },//end of function
        }
    };
</script>

<style scoped>
.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}

.tc-title {
    background-image: linear-gradient(-90deg, #fff, #057996);
    color: #fff;
    border-radius: 3px;
}
table {
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
}
table,
th,
td {
    border: 1px solid black;
}
th {
    height: 10px !important;
    text-align: center !important;
}
th,
td {
    text-align: center !important;
    padding: 4px;
}
</style>
